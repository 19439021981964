import React, { useState, useEffect } from 'react';
import CustomerHeader from './CustomerHeader';
import AddCustomerModal from './AddCustomerModal';
import { getCustomers, Customer } from '../../../api/customersAPI';
import { Link } from 'react-router-dom';
import { useCustomerSearch } from '../../../Components/context/CustomerSearchContext';

const CustomersGrid: React.FC = () => {
  const { search, setSearch } = useCustomerSearch(); // Use context for search
  const [isAddCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [debouncedSearch, setDebouncedSearch] = useState<string>(''); // Debounced search state

  const handleOpenAddCustomerModal = () => {
    setAddCustomerModalOpen(true);
  };

  const handleCloseAddCustomerModal = () => {
    setAddCustomerModalOpen(false);
    fetchCustomers(); // Refresh the customers list when modal is closed
  };

  const fetchCustomers = async () => {
    try {
      setIsLoading(true); // Ensure loading starts
      const page = 1;
      const itemsPerPage = 50;
      const response = await getCustomers(page, itemsPerPage, debouncedSearch); // Use debounced search
      setCustomers(response.customers || []); // Ensure customers are updated
      setError(null);
    } catch (err) {
      const errorMessage = (err as Error).message || 'Failed to fetch customers';
      setError(errorMessage); // Set error message
      setCustomers([]); // Clear customers on error
    } finally {
      setIsLoading(false); // Ensure loading stops
    }
  };

  const handleSearchChange = (searchString: string) => {
    setSearch(searchString); // Update the search state immediately
  };

  // Debounce the search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search); // Update debounced search after 1 second
    }, 500);

    // Cleanup the timeout on every re-render or unmount
    return () => {
      clearTimeout(handler);
    };
  }, [search]); // Run this effect whenever `search` changes

  useEffect(() => {
    fetchCustomers();
  }, [debouncedSearch]); // Fetch customers when the debounced search changes

  return (
    <div id="kt_app_content_container" className="app-container container-xxl">
      <div className="card">
        {/* Customer Header */}
        <CustomerHeader
          onAddCustomerClick={handleOpenAddCustomerModal}
          onSearchChange={handleSearchChange} // Pass the search handler
          searchValue={search} // Pass the current search state as searchValue
        />

        {/* Card Body */}
        <div className="card-body pt-0">
          {error && <div className="alert alert-danger">{error}</div>}
          {isLoading && <div className="text-center py-5">Loading...</div>}

          {!isLoading && !error && customers.length > 0 && (
            <table className="table align-middle table-row-dashed fs-6 gy-5">
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Email</th>
                  <th>Mobile Phone</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer) => (
                  <tr key={customer.customerId}>
                    <td>
                      <Link
                        to={`/customers/${customer.customerId}`}
                        className="text-primary text-hover-underline"
                      >
                        {customer.firstName} {customer.lastName}
                      </Link>
                    </td>
                    <td>{customer.email}</td>
                    <td>{customer.mobilePhone}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {!isLoading && customers.length === 0 && !error && (
            <div className="text-center py-5">No customers found.</div>
          )}
        </div>

        {/* Add Customer Modal */}
        {isAddCustomerModalOpen && (
          <AddCustomerModal
            isOpen={isAddCustomerModalOpen}
            onClose={handleCloseAddCustomerModal}
          />
        )}
      </div>
    </div>
  );
};

export default CustomersGrid;