import React, { createContext, useContext, useState } from 'react';

interface CustomerSearchContextProps {
  search: string;
  setSearch: (value: string) => void;
}

const CustomerSearchContext = createContext<CustomerSearchContextProps | undefined>(undefined);

export const CustomerSearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [search, setSearch] = useState<string>('');

  return (
    <CustomerSearchContext.Provider value={{ search, setSearch }}>
      {children}
    </CustomerSearchContext.Provider>
  );
};

export const useCustomerSearch = (): CustomerSearchContextProps => {
  const context = useContext(CustomerSearchContext);
  if (!context) {
    throw new Error('useCustomerSearch must be used within a CustomerSearchProvider');
  }
  return context;
};
