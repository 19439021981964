import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header/header';
import Sidebar from './Sidebar/sidebar';
import SidebarOverlay from './Sidebar/sidebarOverlay';
import ToolBar from './Toolbar/toolbar';

const MainLayout: React.FC = () => {
  return (
    <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
      <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
        <Header />
        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <Sidebar />
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            {/* Begin Content Wrapper */}
            <div className="d-flex flex-column flex-column-fluid">
              <ToolBar />
              <Outlet /> {/* Nested routes will render here */}
            </div>
            {/* End Content Wrapper */}
          </div>
        </div>
      </div>
      <SidebarOverlay />
    </div>
  );
};

export default MainLayout;
