import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import EditCustomerAvatar from './EditCustomerAvatar';
import CustomerAddressForm from './CustomerAddressForm';
import CustomerForm from './CustomerForm';

interface EditCustomerModalProps {
  isOpen: boolean;
  customerId: number | null; // Customer ID to fetch data or null for a new customer
  onClose: () => void;
  onSubmitSuccess: () => void; // Callback after successful save
}

const EditCustomerModal: React.FC<EditCustomerModalProps> = ({ isOpen, customerId, onClose, onSubmitSuccess }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isUserInfoExpanded, setUserInfoExpanded] = useState<boolean>(true);
    const [isAddressExpanded, setIsAddressExpanded] = useState<boolean>(false);

    const handleAddressToggle = () => {
        setIsAddressExpanded(!isAddressExpanded);
    };
    const handleUserInfoToggle = () => {
        setUserInfoExpanded(!isUserInfoExpanded);
    };    

       
      const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((event.target as HTMLElement).classList.contains('modal')) {
          onClose();
        }
      };
    
      if (!isOpen) {
        return null;
      }

 
      const handleFormCancel = () => {
        console.log("Form canceled");
        onClose();
        // Add logic to close the form or reset the state
      };      
  

  return ReactDOM.createPortal(
    <>
    {/* Backdrop */}
    <div 
      className="modal-backdrop fade show" 
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    ></div>

    {/* Modal container */}
    <div
      className="modal fade show"
      style={{ display: 'block' }}
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      onClick={handleOutsideClick}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {/*begin::Form*/}
            {/*begin::Modal header*/}
            <div className="modal-header" id="kt_modal_update_customer_header">
              {/*begin::Modal title*/}
              <h2 className="fw-bold">Update Customer</h2>
              {/*end::Modal title*/}
              {/*begin::Close*/}
              <div id="kt_modal_update_customer_close" className="btn btn-icon btn-sm btn-active-icon-primary" onClick={onClose}>
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
              {/*end::Close*/}
            </div>
            {/*end::Modal header*/}

            {/*begin::Modal body*/}
            <div className="modal-body py-10 px-lg-17">
                {isLoading && <p>Loading...</p>}
                {error && <p className="text-danger">{error}</p>}
                {!isLoading && (
                <>
                {/*begin::Scroll*/}
                <div
                    className="d-flex flex-column scroll-y me-n7 pe-7"
                    id="kt_modal_update_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_update_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_update_customer_scroll"
                    data-kt-scroll-offset="300px"
                >
                    {/*begin::Notice*/}
                    {/*end::Notice*/}

                    {/*begin::User toggle*/}
                    <div
                    className="fw-bold fs-3 rotate collapsible mb-7"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="kt_modal_update_customer_user_info"
                    onClick={handleUserInfoToggle}
                    >
                    Customer Information{' '}
                    <span className="ms-2 rotate-180">
                        <i className="ki-duotone ki-down fs-3"></i>
                    </span>
                    </div>
                    {/*end::User toggle*/}

                    {/*begin::User form*/}
                    <div id="kt_modal_update_customer_user_info" className={`collapse ${isUserInfoExpanded ? 'show' : ''}`}>
                    
                      <CustomerForm customerId={customerId} onSubmitSuccess={onSubmitSuccess} onCancel={handleFormCancel} />
                    
                    </div>
                    {/*end::User form*/}

                    {/*begin::Billing toggle*/}
                    <div
                    className="fw-bold fs-3 rotate collapsible collapsed mb-7"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="kt_modal_update_customer_billing_info"
                    onClick={handleAddressToggle}
                    >
                    Address Information{' '}
                    <span className="ms-2 rotate-180">
                        <i className="ki-duotone ki-down fs-3"></i>
                    </span>
                    </div>
                    {/*end::Billing toggle*/}
                    {/*begin::Billing form*/}
                    <div id="kt_modal_update_customer_billing_info" className={`collapse ${isAddressExpanded ? 'show' : ''}`} >
                    <CustomerAddressForm
                      customerId= {customerId}
                      onSubmitSuccess={onSubmitSuccess}
                      onCancel={handleFormCancel}
                    />

                    </div>
                    {/*end::Billing form*/}
                </div>
                {/*end::Scroll*/}
                </>
                )} 
                {/* End !isLoading */}
            </div>
            {/*end::Modal body*/}

            {/*begin::Modal footer*/}
            <div className="modal-footer flex-right">
              {/*begin::Button*/}
              <button type="reset" id="kt_modal_update_customer_cancel" className="btn btn-light me-3" onClick={onClose}>
                Discard
              </button>
              {/*end::Button*/}
              {/*begin::Button*/}
              {/* <button type="submit" id="kt_modal_update_customer_submit" className="btn btn-primary">
                <span className="indicator-label">Submit</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button> */}
              {/*end::Button*/}
            </div>
            {/*end::Modal footer*/}

          {/*end::Form*/}
        </div>
      </div>
    </div>
  </>,
    document.body
  );
};

export default EditCustomerModal;
