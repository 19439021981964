import React, { useEffect, useState, useCallback  } from 'react';
import { getCustomerById, Customer } from '../../../api/customersAPI';
import EditCustomerModal from './EditCustomerModal';
import Avatar from './Avatar';
import { getCustomerAddressesByCustomerId, CustomerAddress } from '../../../api/customerAddressAPI';

interface CustomerDetailsSidebarProps {
  customerId: number;
}

const CustomerDetailsSidebar: React.FC<CustomerDetailsSidebarProps> = ({ customerId }) => {
  const [customerData, setCustomerData] = useState<Customer | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [customerAddressData, setcustomerAddressData] = useState<CustomerAddress | null>(null);


  // Fetch customer details
  const fetchCustomerDetails = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getCustomerById(customerId);
      setCustomerData(data);
    } catch (err: any) {
      setError(err.message || 'Failed to fetch customer details');
    } finally {
      setLoading(false);
    }
  }, [customerId]);

    // Fetch customer details
    const fetchCustomerAddress = useCallback(async () => {
      try {
        setLoading(true);
        const addresses = await getCustomerAddressesByCustomerId(customerId);
        setcustomerAddressData(addresses[0]);
      } catch (err: any) {
        setError(err.message || 'Failed to fetch customer address details');
      } finally {
        setLoading(false);
      }
    }, [customerId]);

  // Fetch both customer details and address in parallel
  const fetchCustomerData = useCallback(async () => {
    try {
      setLoading(true);
      await Promise.all([fetchCustomerDetails(), fetchCustomerAddress()]);
    } catch (err: any) {
      setError("Failed to fetch customer data");
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [fetchCustomerDetails, fetchCustomerAddress]);    

    // Load data on component mount or when `customerId` changes
    useEffect(() => {
      if (customerId) {
        fetchCustomerData();
      }
    }, [customerId, fetchCustomerData]);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleUpdateCustomer = (updatedData: any) => {
    // Handle customer update logic here (e.g., make an API call)
    console.log('Updated Customer Data:', updatedData);
    // Update the local state with the new customer data
    setCustomerData((prevData) => ({
      ...prevData!,
      ...updatedData,
    }));
    handleCloseModal();
  };

  if (loading) {
    return <div>Loading customer details...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!customerData) {
    return <div>No customer data available.</div>;
  }

  return (
    <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
      <div className="card mb-5 mb-xl-8">
        <div className="card-body pt-15">
          <div className="d-flex flex-center flex-column mb-5">
            {/* Avatar */}
            <Avatar firstName={customerData.firstName} lastName={customerData.lastName} />

            {/* Name */}
            <a onClick={handleOpenModal} className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1">
              {`${customerData.firstName} ${customerData.lastName}`}
            </a>
            {/* Email */}
            <div className="fs-5 fw-semibold text-muted mb-6">{customerData.email}</div>
            {/* Info */}
            <div className="d-flex flex-wrap flex-center">
              {[customerData.mobilePhone, customerData.workPhone, customerData.homePhone]
                .filter((phone) => phone)
                .map((phone, index) => (
                  <div
                    key={index}
                    className={`border border-gray-300 border-dashed rounded py-3 px-3 ${
                      index === 1 ? 'mx-4' : ''
                    } mb-3`}
                  >
                    <div className="fs-6 fw-bold text-gray-700">{phone}</div>
                  </div>
                ))}
            </div>
          </div>
          {/* Account Details */}
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" role="button" aria-expanded="true">
              Account Details
            </div>
            {/* Edit Toolbar */}
            <div className="d-flex flex-stack fs-4 py-3">
              <button
                type="button"
                className="btn btn-sm btn-light-primary"
                onClick={handleOpenModal}
              >
                Edit
              </button>
            </div>
          </div>
          <div className="separator separator-dashed my-3"></div>
          <div className="py-5 fs-6">
            {[{ label: 'Customer ID', value: customerData.customerId },
              { label: 'Email', value: customerData.email },
            ].map(({ label, value }, index) => (
              <div key={index} className="fw-bold mt-5">
                {label}
                <div className="text-gray-600">{value}</div>
              </div>
            ))}
          </div>
          <div className="fw-bold mt-5">Billing Address</div>
          <div className="text-gray-600">
            {customerAddressData?.addressLine1}
            {customerAddressData?.addressLine2 && (
              <>
                <br />
                {customerAddressData.addressLine2}
              </>
            )}
						<br/>{customerAddressData?.city} {customerAddressData?.state}  {customerAddressData?.postalCode} 
						<br/>{customerAddressData?.country} 
          </div>          
        </div>
      </div>

      {/* Edit Modal */}
      {isModalOpen && (
        <EditCustomerModal
          customerId={customerId}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmitSuccess={() => {
            //alert('Customer saved successfully!');
            fetchCustomerData(); // Reload data
            setModalOpen(false);
        }}
        />
      )}
    </div>
  );
};

export default CustomerDetailsSidebar;
