import React from 'react';

const SignUpFooter: React.FC = () => {
  return (
    <div className="w-lg-500px d-flex flex-stack px-10 mx-auto">
      {/* begin::Languages */}
      <div className="me-10">
        {/* begin::Toggle */}
        <button
          className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-start"
          data-kt-menu-offset="0px, 0px"
        >
          <img
            data-kt-element="current-lang-flag"
            className="w-20px h-20px rounded me-3"
            src="/assets/media/flags/united-states.svg"
            alt=""
          />
          <span data-kt-element="current-lang-name" className="me-1">
            English
          </span>
          <span className="d-flex flex-center rotate-180">
            <i className="ki-duotone ki-down fs-5 text-muted m-0"></i>
          </span>
        </button>
        {/* end::Toggle */}
        {/* begin::Menu */}
        <div
          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7"
          data-kt-menu="true"
          id="kt_auth_lang_menu"
        >
          {/* begin::Menu item */}
          <div className="menu-item px-3">
            <a href="#" className="menu-link d-flex px-5" data-kt-lang="English">
              <span className="symbol symbol-20px me-4">
                <img
                  data-kt-element="lang-flag"
                  className="rounded-1"
                  src="/assets/media/flags/united-states.svg"
                  alt=""
                />
              </span>
              <span data-kt-element="lang-name">English</span>
            </a>
          </div>
          {/* end::Menu item */}
          {/* begin::Menu item */}
          <div className="menu-item px-3">
            <a href="#" className="menu-link d-flex px-5" data-kt-lang="Spanish">
              <span className="symbol symbol-20px me-4">
                <img
                  data-kt-element="lang-flag"
                  className="rounded-1"
                  src="/assets/media/flags/spain.svg"
                  alt=""
                />
              </span>
              <span data-kt-element="lang-name">Spanish</span>
            </a>
          </div>
          {/* end::Menu item */}
          {/* begin::Menu item */}
          <div className="menu-item px-3">
            <a href="#" className="menu-link d-flex px-5" data-kt-lang="German">
              <span className="symbol symbol-20px me-4">
                <img
                  data-kt-element="lang-flag"
                  className="rounded-1"
                  src="/assets/media/flags/germany.svg"
                  alt=""
                />
              </span>
              <span data-kt-element="lang-name">German</span>
            </a>
          </div>
          {/* end::Menu item */}
          {/* begin::Menu item */}
          <div className="menu-item px-3">
            <a href="#" className="menu-link d-flex px-5" data-kt-lang="Japanese">
              <span className="symbol symbol-20px me-4">
                <img
                  data-kt-element="lang-flag"
                  className="rounded-1"
                  src="/assets/media/flags/japan.svg"
                  alt=""
                />
              </span>
              <span data-kt-element="lang-name">Japanese</span>
            </a>
          </div>
          {/* end::Menu item */}
          {/* begin::Menu item */}
          <div className="menu-item px-3">
            <a href="#" className="menu-link d-flex px-5" data-kt-lang="French">
              <span className="symbol symbol-20px me-4">
                <img
                  data-kt-element="lang-flag"
                  className="rounded-1"
                  src="/assets/media/flags/france.svg"
                  alt=""
                />
              </span>
              <span data-kt-element="lang-name">French</span>
            </a>
          </div>
          {/* end::Menu item */}
        </div>
        {/* end::Menu */}
      </div>
      {/* end::Languages */}
      {/* begin::Links */}
      <div className="d-flex fw-semibold text-primary fs-base gap-5">
        <a href="pages/team.html" target="_blank">Terms</a>
        <a href="pages/pricing/column.html" target="_blank">Plans</a>
        <a href="pages/contact.html" target="_blank">Contact Us</a>
      </div>
      {/* end::Links */}
    </div>
  );
};

export default SignUpFooter;
