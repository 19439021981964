import React from 'react';

interface CustomerHeaderProps {
  onAddCustomerClick: () => void;
  onSearchChange: (search: string) => void;
  searchValue: string; // Add a prop for the current search value
}

const CustomerHeader: React.FC<CustomerHeaderProps> = ({ onAddCustomerClick, onSearchChange, searchValue }) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value); // Notify parent of the change
  };

  return (
    <div className="card-header border-0 pt-6">
      {/* Card Title */}
      <div className="card-title">
        {/* Search */}
        <div className="d-flex align-items-center position-relative my-1">
          <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <input
            type="text"
            data-kt-customer-table-filter="search"
            className="form-control form-control-solid w-250px ps-12"
            placeholder="Search Customers"
            value={searchValue} // Bind the input to the current search value
            onChange={handleSearchChange} // Handle changes
          />
        </div>
      </div>

      {/* Card Toolbar */}
      <div className="card-toolbar">
        {/* Add Customer Button */}
        <button type="button" className="btn btn-primary" onClick={onAddCustomerClick}>
          Add Customer
        </button>
      </div>
    </div>
  );
};

export default CustomerHeader;
