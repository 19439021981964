import React, { useState, useEffect, useCallback } from "react";
import { CustomerAddress, getCustomerAddressesByCustomerId, defaultAddressData, postCustomerAddress } from "../../../api/customerAddressAPI";

interface CustomerAddressFormProps {
  customerId?: number | null; // ID to fetch address or null for new address
  onSubmitSuccess: () => void; // Callback for successful submit
  onCancel: () => void; // Cancel handler
}

const CustomerAddressForm: React.FC<CustomerAddressFormProps> = ({
  customerId,
  onSubmitSuccess,
  onCancel,
}) => {
  const [formData, setFormData] = useState<CustomerAddress>(defaultAddressData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch address data if customerId is provided
  useEffect(() => {
    const defaultAddress = { ...defaultAddressData }; // Create a copy of the default data to include the customerid

    if (customerId) {
      defaultAddress.customerId = customerId; // Update customerId only if it exists
    } else {
      throw new Error("Error loading the customer.");
    }
    
    if (customerId) {
      const fetchAddress = async () => {
        try {
          setIsLoading(true);
          const addresses = await getCustomerAddressesByCustomerId(customerId);
          setFormData(addresses[0] || defaultAddress); // Use the first address or default if no addresses exist
        } catch (err: any) {
          console.error("Error fetching address:", err.message);
          setError("Failed to load address data.");
        } finally {
          setIsLoading(false);
        }
      };

      fetchAddress();
    } else {
      setFormData(defaultAddress); // Use default data for new address
    }
  }, [customerId]);

  // Handle input changes
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = event.target;

      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    []
  );

  // Handle form submission
  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      try {
        setIsSaving(true);
        setError(null);

        // Save the address
        console.log("Submitting address:", formData);
        await postCustomerAddress(formData);

        // Notify the parent of successful submission
        onSubmitSuccess();
      } catch (err: any) {
        console.error("Error submitting address:", err.message);
        setError("Failed to save address data.");
      } finally {
        setIsSaving(false);
      }
    },
    [formData, onSubmitSuccess]
  );

  // Render loading and error states
  if (isLoading) {
    return <div>Loading address...</div>;
  }
  if (isSaving) {
    return <div>Saving address...</div>;
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  // Render the form
  return (
    <form onSubmit={handleSubmit}>
      <input type="hidden" value={formData.customerId} onChange={handleChange}></input>

      <div className="d-flex flex-column mb-7 fv-row">
        <label className="fs-6 fw-semibold mb-2">Address Line 1</label>
        <input
          className="form-control form-control-solid"
          placeholder="Address Line 1"
          name="addressLine1"
          value={formData.addressLine1}
          onChange={handleChange}
        />
      </div>

      <div className="d-flex flex-column mb-7 fv-row">
        <label className="fs-6 fw-semibold mb-2">Address Line 2</label>
        <input
          className="form-control form-control-solid"
          placeholder="Address Line 2"
          name="addressLine2"
          value={formData.addressLine2}
          onChange={handleChange}
        />
      </div>

      <div className="d-flex flex-column mb-7 fv-row">
        <label className="fs-6 fw-semibold mb-2">City</label>
        <input
          className="form-control form-control-solid"
          placeholder="City"
          name="city"
          value={formData.city}
          onChange={handleChange}
        />
      </div>

      <div className="row g-9 mb-7">
        <div className="col-md-6 fv-row">
          <label className="fs-6 fw-semibold mb-2">State / Province</label>
          <input
            className="form-control form-control-solid"
            placeholder="State"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 fv-row">
          <label className="fs-6 fw-semibold mb-2">Postal Code</label>
          <input
            className="form-control form-control-solid"
            placeholder="Postal Code"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="d-flex flex-column mb-7 fv-row">
        <label className="fs-6 fw-semibold mb-2">Country</label>
        <select
          name="country"
          className="form-select form-select-solid fw-bold"
          value={formData.country}
          onChange={handleChange}
        >
          <option value="">Select a Country...</option>
          <option value="AU">Australia</option>
          <option value="US">United States</option>
          <option value="GB">United Kingdom</option>
        </select>
      </div>

      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-sm btn-light me-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-sm btn-primary">
          Save Address
        </button>
      </div>
    </form>
  );
};

export default CustomerAddressForm;
