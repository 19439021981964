import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';
import { getErrorMessage } from '../../../Components/auth/firebase/firebase_commons';
import { getUserIdentityByFirebaseId } from '../../../api/userIdentityApi';
import { useUser } from '../../../Components/context/UserContext';

const LoginForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const { setUserIdentity } = useUser();
    
    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Login button clicked');
        console.log('Email:', email);
        console.log('Password:', password);

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const firebaseUserID = userCredential.user.uid;

            // Fetch the user identity by Firebase ID
            const userIdentity = await getUserIdentityByFirebaseId(firebaseUserID);
            setUserIdentity(userIdentity);  // Store the identity in context

            console.log('User logged in successfully:', userCredential);
            setSuccess('User logged in successfully');
            setEmail('');
            setPassword('');
        } catch (err) {
            if (err instanceof Error) {
                const errorMessage = getErrorMessage((err as any).code);
                console.error('Login error:', errorMessage);
                setError(errorMessage);
            } else {
                console.error('Unexpected error occurred during login');
                setError('An unexpected error occurred during login, please try again.');
            }
        }
    };

    return (
        // begin::Form
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <div className="w-lg-500px p-10">
                {/*begin::Form*/}
                <form className="form w-100" noValidate id="kt_login_form" onSubmit={handleLogin}>
                    {/*begin::Heading*/}
                    <div className="text-center mb-11">
                        {/*begin::Title*/}
                        <h1 className="text-gray-900 fw-bolder mb-3">Sign In</h1>
                        {/*end::Title*/}
                        {/*begin::Subtitle*/}
                        <div className="text-gray-500 fw-semibold fs-6">Manage Your Home Services Business with Ease</div>
                        {/*end::Subtitle*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Login options*/}
                    <div className="row g-3 mb-9">
                        {/*begin::Col*/}
                        <div className="col-md-6">
                            {/*begin::Google link*/}
                            <a
                                href="#"
                                className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                            >
                                <img
                                    alt="Logo"
                                    src="/assets/media/svg/brand-logos/google-icon.svg"
                                    className="h-15px me-3"
                                />
                                Sign in with Google
                            </a>
                            {/*end::Google link*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-md-6">
                            {/*begin::Apple link*/}
                            <a
                                href="#"
                                className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                            >
                                <img
                                    alt="Logo"
                                    src="/assets/media/svg/brand-logos/apple-black.svg"
                                    className="theme-light-show h-15px me-3"
                                />
                                <img
                                    alt="Logo"
                                    src="/assets/media/svg/brand-logos/apple-black-dark.svg"
                                    className="theme-dark-show h-15px me-3"
                                />
                                Sign in with Apple
                            </a>
                            {/*end::Apple link*/}
                        </div>
                        {/*end::Col*/}
                    </div>
                    {/*end::Login options*/}
                    {/*begin::Separator*/}
                    <div className="separator separator-content my-14">
                        <span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
                    </div>
                    {/*end::Separator*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                        {/*begin::Email*/}
                        <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            autoComplete="off"
                            className="form-control bg-transparent"
                            value={email || ''}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {/*end::Email*/}
                    </div>
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8" data-kt-password-meter="true">
                        {/*begin::Wrapper*/}
                        <div className="mb-1">
                            {/*begin::Input wrapper*/}
                            <div className="position-relative mb-3">
                                <input
                                    className="form-control bg-transparent"
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    autoComplete="off"
                                    value={password || ''}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span
                                    className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                    data-kt-password-meter-control="visibility"
                                >
                                    <i className="ki-duotone ki-eye-slash fs-2"></i>
                                    <i className="ki-duotone ki-eye fs-2 d-none"></i>
                                </span>
                            </div>
                            {/*end::Input wrapper*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Submit button*/}
                    <div className="d-grid mb-10">
                        <button type="submit" id="kt_login_submit" className="btn btn-primary">
                            {/*begin::Indicator label*/}
                            <span className="indicator-label">Sign in</span>
                            {/*end::Indicator label*/}
                            {/*begin::Indicator progress*/}
                            <span className="indicator-progress">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                            {/*end::Indicator progress*/}
                        </button>
                    </div>
                    {/*end::Submit button*/}
                    {/*begin::Sign up*/}
                    <div className="text-gray-500 text-center fw-semibold fs-6">
                        Don't have an account? <a href="/register" className="link-primary fw-semibold">Sign up</a>
                    </div>
                    {/*end::Sign up*/}
                </form>
                {/*end::Form*/}
                {error && <div className="text-center text-danger mt-3">{error}</div>}
                {success && <div className="text-center text-success mt-3">{success}</div>}
            </div>
        </div>
        // end::Form
    );
};

export default LoginForm;
