import React from 'react';

interface CustomerDetailsTabProps {
  onTabChange: (tab: string) => void;
  activeTab: string;
}

const CustomerDetailsTab: React.FC<CustomerDetailsTabProps> = ({ onTabChange, activeTab }) => {
  return (
    <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
      <li className="nav-item">
        <a
          className={`nav-link text-active-primary pb-4 ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => onTabChange('overview')}
          role="button"
        >
          Overview
        </a>
      </li>
      <li className="nav-item">
        <a
          className={`nav-link text-active-primary pb-4 ${activeTab === 'events_logs' ? 'active' : ''}`}
          onClick={() => onTabChange('events_logs')}
          role="button"
        >
          Events & Logs
        </a>
      </li>
      <li className="nav-item">
        <a
          className={`nav-link text-active-primary pb-4 ${activeTab === 'statements' ? 'active' : ''}`}
          onClick={() => onTabChange('statements')}
          role="button"
        >
          Statements
        </a>
      </li>
    </ul>
  );
};

export default CustomerDetailsTab;
