import React from 'react';

const CustomerQuoteRecords: React.FC = () => {
  return (
    <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
      {/* Payment Records */}
      <div className="card pt-4 mb-6 mb-xl-9">
        <div className="card-header border-0">
          <div className="card-title">
            <h2>Payment Records</h2>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-flex btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_add_payment"
            >
              <i className="ki-duotone ki-plus-square fs-3">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
              Add payment
            </button>
          </div>
        </div>
        <div className="card-body pt-0 pb-5">
          <table className="table align-middle table-row-dashed gy-5" id="kt_table_customers_payment">
            <thead className="border-bottom border-gray-200 fs-7 fw-bold">
              <tr className="text-start text-muted text-uppercase gs-0">
                <th className="min-w-100px">Invoice No.</th>
                <th>Status</th>
                <th>Amount</th>
                <th className="min-w-100px">Date</th>
                <th className="text-end min-w-100px pe-4">Actions</th>
              </tr>
            </thead>
            <tbody className="fs-6 fw-semibold text-gray-600">
              {[
                { invoice: "9570-9426", status: "Successful", amount: "$1,200.00", date: "14 Dec 2020, 8:43 pm" },
                { invoice: "5780-1553", status: "Successful", amount: "$79.00", date: "01 Dec 2020, 10:12 am" },
                { invoice: "6355-9053", status: "Successful", amount: "$5,500.00", date: "12 Nov 2020, 2:01 pm" },
              ].map((record, index) => (
                <tr key={index}>
                  <td>
                    <a href="#" className="text-gray-600 text-hover-primary mb-1">
                      {record.invoice}
                    </a>
                  </td>
                  <td>
                    <span className={`badge badge-light-${record.status === "Successful" ? "success" : "warning"}`}>
                      {record.status}
                    </span>
                  </td>
                  <td>{record.amount}</td>
                  <td>{record.date}</td>
                  <td className="pe-0 text-end">
                    <a
                      href="#"
                      className="btn btn-sm btn-light btn-active-light-primary"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                    >
                      Actions
                      <i className="ki-duotone ki-down fs-5 ms-1"></i>
                    </a>
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                      data-kt-menu="true"
                    >
                      <div className="menu-item px-3">
                        <a href="#" className="menu-link px-3">
                          View
                        </a>
                      </div>
                      <div className="menu-item px-3">
                        <a href="#" className="menu-link px-3" data-kt-customer-table-filter="delete_row">
                          Delete
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Payment Methods */}
      <div className="card pt-4 mb-6 mb-xl-9">
        <div className="card-header border-0">
          <div className="card-title">
            <h2 className="fw-bold mb-0">Payment Methods</h2>
          </div>
          <div className="card-toolbar">
            <a href="#" className="btn btn-sm btn-flex btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">
              <i className="ki-duotone ki-plus-square fs-3">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
              Add new method
            </a>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="py-0">
            <div className="py-3 d-flex flex-stack flex-wrap">
              <div
                className="d-flex align-items-center collapsible rotate"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="kt_customer_view_payment_method_1"
              >
                <div className="me-3 rotate-90">
                  <i className="ki-duotone ki-right fs-3"></i>
                </div>
                <img src="/assets/media/svg/card-logos/mastercard.svg" className="w-40px me-3" alt="" />
                <div className="me-3">
                  <div className="d-flex align-items-center">
                    <div className="text-gray-800 fw-bold">Mastercard</div>
                    <div className="badge badge-light-primary ms-5">Primary</div>
                  </div>
                  <div className="text-muted">Expires Dec 2024</div>
                </div>
              </div>
              <div className="d-flex my-3 ms-9">
                <a href="#" className="btn btn-icon btn-active-light-primary w-30px h-30px me-3">
                  <i className="ki-duotone ki-pencil fs-3"></i>
                </a>
                <a href="#" className="btn btn-icon btn-active-light-primary w-30px h-30px me-3">
                  <i className="ki-duotone ki-trash fs-3"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default CustomerQuoteRecords;
