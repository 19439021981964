import React from 'react';

const SignUpAside: React.FC = () => {
  return (
    <div
      className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
      style={{ backgroundImage: 'url(assets/media/misc/auth-bg.png)' }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
        {/* begin::Logo */}
        <a href="index.html" className="mb-0 mb-lg-12">
          <img alt="Logo" src="/assets/media/logos/custom-1.png" className="h-60px h-lg-75px" />
        </a>
        {/* end::Logo */}
        {/* begin::Image */}
        <img
          className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
          src="/assets/media/misc/auth-screens.png"
          alt=""
        />
        {/* end::Image */}
        {/* begin::Title */}
        <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">
          Fast, Efficient and Productive
        </h1>
        {/* end::Title */}
        {/* begin::Text */}
        <div className="d-none d-lg-block text-white fs-base text-center">
          In this kind of post,
          <a href="#" className="opacity-75-hover text-warning fw-bold me-1">
            the blogger
          </a>
          introduces a person they’ve interviewed
          <br />
          and provides some background information about
          <a href="#" className="opacity-75-hover text-warning fw-bold me-1">
            the interviewee
          </a>
          and their
          <br />
          work following this is a transcript of the interview.
        </div>
        {/* end::Text */}
      </div>
      {/* end::Content */}
    </div>
  );
};

export default SignUpAside;
