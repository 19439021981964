import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QuoteGridHeader from './QuoteGridHeader';

const QuotesGrid: React.FC = () => {

    const handleSearchChange = (searchString: string): void => {
        // Add your implementation here
        console.log("Search string changed:", searchString);
      };

      const handleAddQuoteClick = (): void => {
        // Add your implementation here
        console.log("handleOpenAddCustomerModal clicked:");
      };      

  return (
    <div id="kt_app_content_container" className="app-container container-xxl">
      <div className="card">
        {/* Quotes Header */}
        <QuoteGridHeader
          onAddQuoteClick={handleAddQuoteClick}
          onSearchChange={handleSearchChange} // Pass the search handler
        />        

        {/* Card Body */}
        <div className="card-body pt-0">
            <table className="table align-middle table-row-dashed fs-6 gy-5">
              <thead>
                <tr>
                  <th>Quote #</th>
                  <th>Client Name</th>
                  <th>Quote Description</th>
                  <th>Date Created</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                
              </tbody>
            </table>
        </div>

      </div>
    </div>
  );
};

export default QuotesGrid;