import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { AppProvider } from './Components/context/AppContext';
import { AppRoutes } from './Components/routing/AppRoutes';
import { AuthProvider } from './Components/auth/authProvider';
import { UserProvider } from './Components/context/UserContext';
import { CustomerSearchProvider } from './Components/context/CustomerSearchContext';

const App: React.FC = () => {

  return (
    <AuthProvider>
      <AppProvider>
        <UserProvider>
          <CustomerSearchProvider>
            <AppRoutes />
          </CustomerSearchProvider>
        </UserProvider>
      </AppProvider>
    </AuthProvider>
  );
}

export default App;