import axios from 'axios';

// Define the Customer type
export interface CustomerAddress {
    addressId: number;
    customerId: number;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    // createdAt: string | null; // ISO string for the date
    // updatedAt: string | null; // ISO string or null for the date
  }
  

  // Function to fetch customer addresses by customer ID
export const getCustomerAddressesByCustomerId = async (customerId: number): Promise<CustomerAddress[]> => {
    try {
        console.log(`[getCustomerAddressesByCustomerId] Fetching addresses for customer ID: ${customerId}`);
        const url = `${process.env.REACT_APP_API_URL}/api/CustomerAddress/byCustomer/${customerId}`;
        const response = await axios.get<CustomerAddress[]>(url);
        return response.data; // Return the array of customer addresses
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to fetch customer addresses');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

  // Function to fetch customer addresses by customer ID
  export const getCustomerAddressByAddressId = async (addressID: number): Promise<CustomerAddress> => {
    try {
        console.log(`[getCustomerAddressByAddressId] Fetching address for address ID: ${addressID}`);
        const url = `${process.env.REACT_APP_API_URL}/api/CustomerAddress/${addressID}`;
        const response = await axios.get<CustomerAddress>(url);
        return response.data; // Return the array of customer addresses
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to fetch addresses');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// Function to create or update a customer address
export const postCustomerAddress = async (customerAddress: CustomerAddress): Promise<CustomerAddress> => {
    try {
      const isUpdate = customerAddress.addressId > 0; // Check if the address ID exists to determine update vs create
      const url = isUpdate
        ? `${process.env.REACT_APP_API_URL}/api/CustomerAddress/${customerAddress.addressId}` // Update URL
        : `${process.env.REACT_APP_API_URL}/api/CustomerAddress`; // Create URL
  
      const method = isUpdate ? 'put' : 'post'; // Use PUT for update and POST for create
  
      console.log(`[postCustomerAddress] ${isUpdate ? 'Updating' : 'Creating'} address:`, customerAddress);
  
      const response = await axios({
        method,
        url,
        data: customerAddress,
      });
  
      return response.data; // Return the saved address object
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data || 'Failed to save customer address');
      } else {
        throw new Error('An unexpected error occurred');
      }
    }
  };
  
  export const defaultAddressData: CustomerAddress = {
    addressId: 0,
    customerId: 0,
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: ""
    // createdAt: "",
    // updatedAt: "",
  };
  
  const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };