import React, { useState } from "react";
import QuoteFormItemsTable from "./QuoteFormItemsTable";

const QuoteForm = () => {
    const [items, setItems] = useState([
        { name: "", description: "", quantity: 1, price: 0 },
      ]);
    
    const handleAddItem = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // Prevent default form submission behavior
        setItems([...items, { name: "", description: "", quantity: 1, price: 0 }]);
    };
    
      const handleRemoveItem = (index: number) => {
        setItems(items.filter((_, i) => i !== index));
      };
    
      const handleInputChange = (
        index: number,
        field: keyof typeof items[0],
        value: string | number
      ) => {
        setItems((prevItems) =>
          prevItems.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
          )
        );
      };


  return (
    <div id="kt_app_content_container" className="app-container container-xxl">
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
          <div className="card">
            <div className="card-body p-12">
              <form action="" id="kt_invoice_form">
                <div className="d-flex flex-column align-items-start flex-xxl-row">
                  <div
                    className="d-flex align-items-center flex-equal fw-row me-4 order-2"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Specify invoice date"
                  >
                    <div className="fs-6 fw-bold text-gray-700 text-nowrap">
                      Date:
                    </div>
                    <div className="position-relative d-flex align-items-center w-150px">
                      <input
                        className="form-control form-control-transparent fw-bold pe-5"
                        placeholder="Select date"
                        name="invoice_date"
                      />
                      <i className="ki-duotone ki-down fs-4 position-absolute ms-4 end-0"></i>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Enter invoice number"
                  >
                    <span className="fs-2x fw-bold text-gray-800">
                      Invoice #
                    </span>
                    <input
                      type="text"
                      className="form-control form-control-flush fw-bold text-muted fs-3 w-125px"
                      value="2021001"
                      placeholder="..."
                    />
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-end flex-equal order-3 fw-row"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Specify invoice due date"
                  >
                    <div className="fs-6 fw-bold text-gray-700 text-nowrap">
                      Due Date:
                    </div>
                    <div className="position-relative d-flex align-items-center w-150px">
                      <input
                        className="form-control form-control-transparent fw-bold pe-5"
                        placeholder="Select date"
                        name="invoice_due_date"
                      />
                      <i className="ki-duotone ki-down fs-4 position-absolute end-0 ms-4"></i>
                    </div>
                  </div>
                </div>
                <div className="separator separator-dashed my-10"></div>
                <div className="mb-0">
                  <div className="row gx-10 mb-5">
                    <div className="col-lg-6">
                      <label className="form-label fs-6 fw-bold text-gray-700 mb-3">
                        Bill From
                      </label>
                      <div className="mb-5">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder="Name"
                        />
                      </div>
                      <div className="mb-5">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder="Email"
                        />
                      </div>
                      <div className="mb-5">
                        <textarea
                          name="notes"
                          className="form-control form-control-solid"
                          rows={3}
                          placeholder="Who is this invoice from?"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label fs-6 fw-bold text-gray-700 mb-3">
                        Bill To
                      </label>
                      <div className="mb-5">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder="Name"
                        />
                      </div>
                      <div className="mb-5">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder="Email"
                        />
                      </div>
                      <div className="mb-5">
                        <textarea
                          name="notes"
                          className="form-control form-control-solid"
                          rows={3}
                          placeholder="What is this invoice for?"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <QuoteFormItemsTable
                        items={items}
                        onAdd={handleAddItem}
                        onRemove={handleRemoveItem}
                        onChange={handleInputChange}
                    />


                  <table className="table d-none" data-kt-element="item-template">
                    <tr
                      className="border-bottom border-bottom-dashed"
                      data-kt-element="item"
                    >
                      <td className="pe-7">
                        <input
                          type="text"
                          className="form-control form-control-solid mb-2"
                          name="name[]"
                          placeholder="Item name"
                        />
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="description[]"
                          placeholder="Description"
                        />
                      </td>
                      <td className="ps-0">
                        <input
                          className="form-control form-control-solid"
                          type="number"
                          min={1}
                          name="quantity[]"
                          placeholder="1"
                          data-kt-element="quantity"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control form-control-solid text-end"
                          name="price[]"
                          placeholder="0.00"
                          data-kt-element="price"
                        />
                      </td>
                      <td className="pt-8 text-end">
                        $
                        <span data-kt-element="total">0.00</span>
                      </td>
                      <td className="pt-5 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-icon btn-active-color-primary"
                          data-kt-element="remove-item"
                        >
                          <i className="ki-duotone ki-trash fs-3">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                            <span className="path5"></span>
                          </i>
                        </button>
                      </td>
                    </tr>
                  </table>
                  <table className="table d-none" data-kt-element="empty-template">
                    <tr data-kt-element="empty">
                      <th colSpan={5} className="text-muted text-center py-10">
                        No items
                      </th>
                    </tr>
                  </table>
                  <div className="mb-0">
                    <label className="form-label fs-6 fw-bold text-gray-700">
                      Notes
                    </label>
                    <textarea
                      name="notes"
                      className="form-control form-control-solid"
                      rows={3}
                      placeholder="Thanks for your business"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="flex-lg-auto min-w-lg-300px">
          <div
            className="card"
            data-kt-sticky="true"
            data-kt-sticky-name="invoice"
            data-kt-sticky-offset="{default: false, lg: '200px'}"
            data-kt-sticky-width="{lg: '250px', lg: '300px'}"
            data-kt-sticky-left="auto"
            data-kt-sticky-top="150px"
            data-kt-sticky-animation="false"
            data-kt-sticky-zindex="95"
          >
            <div className="card-body p-10">
              <div className="mb-10">
                <label className="form-label fw-bold fs-6 text-gray-700">
                  Currency
                </label>
                <select
                  name="currnecy"
                  aria-label="Select a Timezone"
                  data-control="select2"
                  data-placeholder="Select currency"
                  className="form-select form-select-solid"
                >
                  <option value=""></option>
                  <option data-kt-flag="flags/united-states.svg" value="USD">
                    <b>USD</b>&nbsp;-&nbsp;USA dollar
                  </option>
                  <option data-kt-flag="flags/united-kingdom.svg" value="GBP">
                    <b>GBP</b>&nbsp;-&nbsp;British pound
                  </option>
                  <option data-kt-flag="flags/australia.svg" value="AUD">
                    <b>AUD</b>&nbsp;-&nbsp;Australian dollar
                  </option>
                  <option data-kt-flag="flags/japan.svg" value="JPY">
                    <b>JPY</b>&nbsp;-&nbsp;Japanese yen
                  </option>
                  <option data-kt-flag="flags/sweden.svg" value="SEK">
                    <b>SEK</b>&nbsp;-&nbsp;Swedish krona
                  </option>
                  <option data-kt-flag="flags/canada.svg" value="CAD">
                    <b>CAD</b>&nbsp;-&nbsp;Canadian dollar
                  </option>
                  <option data-kt-flag="flags/switzerland.svg" value="CHF">
                    <b>CHF</b>&nbsp;-&nbsp;Swiss franc
                  </option>
                </select>
              </div>
              <div className="separator separator-dashed mb-8"></div>
              <div className="mb-8">
                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5">
                  <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">
                    Payment method
                  </span>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked
                    value=""
                  />
                </label>
                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5">
                  <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">
                    Late fees
                  </span>
                  <input className="form-check-input" type="checkbox" value="" />
                </label>
                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                  <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">
                    Notes
                  </span>
                  <input className="form-check-input" type="checkbox" value="" />
                </label>
              </div>
              <div className="separator separator-dashed mb-8"></div>
              <div className="mb-0">
                <div className="row mb-5">
                  <div className="col">
                    <a
                      href="#"
                      className="btn btn-light btn-active-light-primary w-100"
                    >
                      Preview
                    </a>
                  </div>
                  <div className="col">
                    <a
                      href="#"
                      className="btn btn-light btn-active-light-primary w-100"
                    >
                      Download
                    </a>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  id="kt_invoice_submit_button"
                >
                  <i className="ki-duotone ki-triangle fs-3">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                  Send Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

);
};

export default QuoteForm;