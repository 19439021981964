import React, { useContext } from "react";
import { AuthContext } from '../../Components/auth/authContext';
import { useUser } from "../../Components/context/UserContext";
import { Outlet } from "react-router-dom";

const QuotesPage: React.FC = () => {
  const currentUser = useContext(AuthContext);
  const { userIdentity } = useUser();

  return (
    //<!--begin::Content-->
    <div id="kt_app_content" className="app-content flex-column-fluid">
      

      {/* <!--begin::Content container--> */}
        <Outlet />
        {/* <NoCustomers/> */}
        {/* <CustomersGrid/> */}
      {/* <!--end::Content container--> */}
    </div>
  );
};

export { QuotesPage };
