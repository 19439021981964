import React, { useState, useEffect } from "react";
import { getCustomerById, Customer, postCustomer } from "../../../api/customersAPI";

interface CustomerFormProps {
  customerId: number | null; // ID of the customer to edit
  onSubmitSuccess: () => void; // Callback for successful submit
  onCancel: () => void; // Cancel handler
}

const CustomerForm: React.FC<CustomerFormProps> = ({ customerId, onSubmitSuccess, onCancel}) => {
  const [formState, setFormState] = useState<Customer>({
    customerId: 0,
    firstName: "",
    lastName: "",
    email: "",
    mobilePhone: "",
    homePhone: "",
    workPhone: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch customer data when the form opens
  useEffect(() => {
    if (customerId) {
      const fetchCustomer = async () => {
        try {
          setIsLoading(true);
          const data = await getCustomerById(customerId);
          setFormState(data);
        } catch (err: any) {
          console.error("Error fetching customer:", err.message);
          setError("Failed to load customer data.");
        } finally {
          setIsLoading(false);
        }
      };
      fetchCustomer();
    } else {
      setFormState({
        customerId: 0,
        firstName: "",
        lastName: "",
        email: "",
        mobilePhone: "",
        homePhone: "",
        workPhone: "",
      });
    }
  }, [customerId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null); // Clear previous errors

    try {
      setIsLoading(true);
      console.log("Saving customer data:", formState);

      await postCustomer(formState);

      onSubmitSuccess(); // Notify the parent of success
    } catch (err) {
      console.error("Error submitting form:", err);
      setError("Failed to save customer data.");
    } finally {
      setIsLoading(false);
    }
  };

  // Render loading, error messages, and form
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  return (
    <form className="form" id="kt_modal_update_customer_form" onSubmit={handleSubmit}>
      <div className="row g-9 mb-7">
        {/* First Name */}
        <div className="col-md-6 fv-row">
          <label className="fs-6 fw-semibold mb-2">First Name</label>
          <input
            className="form-control form-control-solid"
            placeholder="Enter First Name"
            name="firstName"
            value={formState.firstName}
            onChange={handleChange}
          />
        </div>
        {/* Last Name */}
        <div className="col-md-6 fv-row">
          <label className="fs-6 fw-semibold mb-2">Last Name</label>
          <input
            className="form-control form-control-solid"
            placeholder="Enter Last Name"
            name="lastName"
            value={formState.lastName}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Email */}
      <div className="fv-row mb-7">
        <label className="fs-6 fw-semibold mb-2">Email</label>
        <input
          type="email"
          className="form-control form-control-solid"
          placeholder="Enter Email"
          name="email"
          value={formState.email}
          onChange={handleChange}
        />
      </div>

      {/* Mobile Phone */}
      <div className="fv-row mb-7">
        <label className="fs-6 fw-semibold mb-2">Mobile Phone Number</label>
        <input
          type="text"
          className="form-control form-control-solid"
          placeholder="Enter Mobile Phone"
          name="mobilePhone"
          value={formState.mobilePhone}
          onChange={handleChange}
        />
      </div>

      {/* Home Phone and Work Phone */}
      <div className="row g-9 mb-7">
        <div className="col-md-6 fv-row">
          <label className="fs-6 fw-semibold mb-2">Home Phone Number</label>
          <input
            className="form-control form-control-solid"
            placeholder="Enter Home Phone"
            name="homePhone"
            value={formState.homePhone}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 fv-row">
          <label className="fs-6 fw-semibold mb-2">Work Phone Number</label>
          <input
            className="form-control form-control-solid"
            placeholder="Enter Work Phone"
            name="workPhone"
            value={formState.workPhone}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="fv-row mb-15">
        <div className="d-flex justify-content-end">
          <button type="button" className="btn btn-sm btn-light me-3" onClick={onCancel}>
            Cancel
          </button>
          <button type="submit" className="btn btn-sm btn-primary">
            Save Customer
          </button>
        </div>
      </div>
    </form>
  );
};

export default CustomerForm;
