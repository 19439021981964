import React from 'react';

interface AvatarProps {
  firstName: string;
  lastName?: string;
  imageUrl?: string; // Optional image URL
}

const Avatar: React.FC<AvatarProps> = ({ firstName, lastName, imageUrl }) => {
  // Get initials (first letter of the first name and optionally the last name)
  const initials = firstName.charAt(0).toUpperCase() + (lastName ? lastName.charAt(0).toUpperCase() : '');

  const dynamicBackgroundColor = (name: string): string => {
    const colors = ['#6c757d', '#007bff', '#28a745', '#dc3545', '#ffc107'];
    const index = name.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const backgroundColor = dynamicBackgroundColor(firstName);

  return (
    <div className={`symbol symbol-100px symbol-circle mb-7`}>
      {imageUrl ? (
        <img src={imageUrl} alt={`${firstName} ${lastName}`} />
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: backgroundColor, // Fallback background color
            color: '#fff', // Text color
            borderRadius: '50%', // Ensures circular shape
            fontWeight: 'bold',
            fontSize: '40px',
            width: '100px',
            height: '100px',
          }}
        >
          {initials}
        </div>
      )}
    </div>
  );
};

export default Avatar;
